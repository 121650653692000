import { NgModule, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseAlertModule, FuseModule } from '@fleet/fuse';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthInterceptor } from './services';
import { VerificationGuard } from './guards/verification.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',

    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'invitation/:activationkey',
    loadChildren: () =>
      import('./components/invitation/invitation.module').then(
        (m) => m.InvitationModule
      ),
  },
  {
    path: 'signup',
    canActivate: [VerificationGuard],
    loadChildren: () =>
      import('./components/signup/signup.module').then((m) => m.SignupModule),
  },

  // { path: "error", component: ErrorReloadComponent },

  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./components/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'change-username',
    loadChildren: () =>
      import('./components/change-username/change-username.module').then(
        (m) => m.ChangeUsernameModule
      ),
  },
  {
    path: 'change-number',
    loadChildren: () =>
      import('./components/change-phonenumber/change-phonenumber.module').then(
        (m) => m.ChangePhonenumberModule
      ),
  },

  {
    path: 'reset-password',
    canActivate: [VerificationGuard],
    loadChildren: () =>
      import('./components/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'reset-password/:activationKey',
    loadChildren: () =>
      import('./components/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'magic-link',
    loadChildren: () =>
      import('./components/magic-link/magic-link.module').then(
        (m) => m.MagicLinkModule
      ),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./components/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'driver-lookup',
    loadChildren: () =>
      import('./components/driver-lookup/driver-lookup.module').then(
        (m) => m.DriverLookupModule
      ),
  },
  {
    path: 'verify-phone',
    loadChildren: () =>
      import('./components/verify-phone/verify-phone.module').then(
        (m) => m.VerifyPhoneModule
      ),
  },
  {
    path: 'verify',
    canActivate: [VerificationGuard],
    loadChildren: () =>
      import('./components/verify-code/verify-code.module').then(
        (m) => m.VerifyCodeModule
      ),
  },
  {
    path: 'verified',
    loadChildren: () =>
      import('./components/verified/verified.module').then(
        (m) => m.VerifiedModule
      ),
  },
  {
    path: 'existing-user',
    // canActivate: [VerificationGuard],
    loadChildren: () =>
      import('./components/existing-user/existing-user.module').then(
        (m) => m.ExistingUserModule
      ),
  },
  {
    path: 'welcome',

    loadChildren: () =>
      import('./components/welcome/welcome.module').then(
        (m) => m.WelcomeModule
      ),
  },

  {
    path: 'saml/login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },

  {
    path: 'apple-signin',
    loadChildren: () =>
      import(
        './components/apple-sign-in-button/apple-sign-in-button.module'
      ).then((m) => m.AppleSignInButtonModule),
  },
  {
    path: 'apple-signin-callback',
    loadChildren: () =>
      import(
        './components/apple-signin-callback/apple-signin-callback.module'
      ).then((m) => m.AppleSigninCallbackModule),
  },

  {
    path: 'google-signin',
    loadChildren: () =>
      import(
        './components/google-signin-button/google-signin-button.module'
      ).then((m) => m.GoogleSigninButtonModule),
  },

  {
    path: 'google-signin-callback',
    loadChildren: () =>
      import(
        './components/google-signin-callback/google-signin-callback.module'
      ).then((m) => m.GoogleSigninCallbackModule),
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatCheckboxModule,
    RouterModule.forChild(routes),
    FuseAlertModule,

    MatProgressSpinnerModule,
    AlertsFromIssuesModule,
  ],

  exports: [],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
