import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppleSignInButtonComponent } from './apple-sign-in-button.component';
import { RouterModule, Routes } from '@angular/router';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';

// const routes: Routes = [{ path: '', component: AppleSignInButtonComponent }];
@NgModule({
  declarations: [AppleSignInButtonComponent],
  imports: [
    CommonModule,
    // RouterModule.forChild(routes),

    FuseAlertModule,
    AlertsFromIssuesModule,
  ],
  exports: [AppleSignInButtonComponent],
})
export class AppleSignInButtonModule {}
