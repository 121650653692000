<ng-container *transloco="let t">
  <form
    [formGroup]="form"
    *ngIf="form && paymentType"
    class="flex flex-col flex-auto"
  >
    <div class="flex flex-col" [ngClass]="{ 'mt-8 px-6': padding }">
      <mat-form-field
        *ngIf="includeBankName"
        [subscriptSizing]="'dynamic'"
        class="w-full mb-4"
      >
        <mat-label>{{ t('accountModelForm.bankNameLabel') }}</mat-label>
        <input
          matInput
          [placeholder]="t('accountModelForm.bankNamePlaceholder')"
          formControlName="bankName"
          maxlength="32"
          required
          style="text-transform: uppercase"
          #bankNameInput
        />
        <mat-error *ngIf="form.get('bankName').hasError('required')">
          {{ t('accountModelForm.bankNameErrorsRequired') }}
        </mat-error>
        <mat-error
          *ngIf="
            form.get('bankName').hasError('pattern') ||
            form.get('bankName').hasError('valid')
          "
        >
          {{ t('accountModelForm.bankNameErrorsPattern') }}
        </mat-error>
        <mat-error *ngIf="form.get('bankName').hasError('maxlength')">
          {{ t('accountModelForm.bankNameErrorsMaxlength') }}
        </mat-error>
      </mat-form-field>

      <ng-container *ngIf="bankAccountTypes?.length">
        <div class="flex flex-col">
          <mat-form-field appearance="fill">
            <mat-label>{{
              t('accountModelForm.bankAccountTypeLabel')
            }}</mat-label>
            <mat-select formControlName="accountType" required>
              <mat-option
                *ngFor="let type of bankAccountTypes"
                [value]="type.type"
              >
                {{ type.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('accountType').hasError('required')">
              {{ t('accountModelForm.bankAccountTypeErrorsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <mat-form-field class="mb-4 w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ t('accountModelForm.routingNumberLabel') }}</mat-label>
        <input
          type="tel"
          matInput
          [placeholder]="t('accountModelForm.routingNumberPlaceholder')"
          formControlName="bankCode"
          [maxlength]="bankCodeLength"
          required
          #bankCodeInput
        />
        <mat-error *ngIf="form.get('bankCode').hasError('required')">
          {{ t('accountModelForm.routingNumberErrorsRequired') }}
        </mat-error>
        <mat-error
          *ngIf="
            form.get('bankCode').hasError('pattern') ||
            form.get('bankCode').hasError('valid')
          "
        >
          {{ t('accountModelForm.routingNumberErrorsPattern') }}
        </mat-error>
        <mat-error *ngIf="form.get('bankCode').hasError('maxlength')">
          {{ t('accountModelForm.routingNumberErrorsMaxlength') }}
        </mat-error>
      </mat-form-field>
      <!-- </ng-template> -->

      <mat-form-field class="mb-4 w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ t('accountModelForm.accountNumberLabel') }}</mat-label>
        <input
          type="tel"
          matInput
          [placeholder]="t('accountModelForm.accountNumberPlaceholder')"
          formControlName="accountNumber"
          maxlength="9"
          required
        />
        <mat-error *ngIf="form.get('accountNumber').hasError('required')">
          {{ t('accountModelForm.accountNumberErrorsRequired') }}
        </mat-error>
        <mat-error
          *ngIf="
            form.get('accountNumber').hasError('pattern') ||
            form.get('accountNumber').hasError('valid')
          "
        >
          {{ t('accountModelForm.accountNumberErrorsPattern') }}
        </mat-error>
        <mat-error *ngIf="form.get('accountNumber').hasError('maxlength')">
          {{ t('accountModelForm.accountNumberErrorsMaxlength') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field [subscriptSizing]="'dynamic'" class="w-full mb-4">
        <mat-label>{{ t('accountModelForm.accountNameLabel') }}</mat-label>
        <input
          matInput
          [placeholder]="t('accountModelForm.accountNamePlaceholder')"
          formControlName="accountName"
          maxlength="32"
          required
          style="text-transform: uppercase"
          #accountNameInput
        />
        <mat-error *ngIf="form.get('accountName').hasError('required')">
          {{ t('accountModelForm.accountNameErrorsRequired') }}
        </mat-error>
        <mat-error
          *ngIf="
            form.get('accountName').hasError('pattern') ||
            form.get('accountName').hasError('valid')
          "
        >
          {{ t('accountModelForm.accountNameErrorsPattern') }}
        </mat-error>
        <mat-error *ngIf="form.get('accountName').hasError('maxlength')">
          {{ t('accountModelForm.accountNameErrorsMaxlength') }}
        </mat-error>
      </mat-form-field>

      <ng-container
        *ngIf="disbursementFrequencies?.length && !hideDisbursementFrequency"
      >
        <fleet-radio-group-list-with-description
          formControlName="disbursementFrequency"
          [options]="disbursementFrequencies"
          [label]="t('accountModelForm.disbursementFrequencyLabel')"
        ></fleet-radio-group-list-with-description>
      </ng-container>
    </div>
  </form>
</ng-container>
