import {
  LoginModel,
  OrganisationUserModel,
  SignupModel,
  ValidationModel,
  VerificationModel,
} from '@fleet/model';
// import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  sendMFACode,
  sendMFACodeSuccess,
  UserAuthActionTypes,
  verifyMFA,
  verifyMFAFailure,
  verifyMFASuccess,
  verifyCodeForEmail,
  verifyCodeForEmailFailure,
  verifyCodeForEmailSuccess,
  sendMFACodeFailure,
  configureMFA,
  samlLogin,
  samlLoginFailure,
} from './user-auth.actions';
import { handleApiErrorSync } from '@fleet/utilities';
import { Params } from '@angular/router';

export interface UserAuthState {
  loading: boolean;
  error: any;
  user: any;
  locked: boolean;
  roles: string[];
  verificationModel: VerificationModel;
  loginError: any;
  signUpError: any;
  validating: boolean;
  userNameValid: boolean;
  usernameValidation: ValidationModel;
  verifyType: string;
  routedFrom: string;
  userLookupValidation: ValidationModel;
  signupModel: SignupModel;
  loginModel: LoginModel;
  mfaSettings?: any;
  samlQueryParams: Params;
  externalSignin: boolean;
}

export const initialState: UserAuthState = {
  error: null,
  loginError: null,
  signUpError: null,
  loading: false,
  user: {},
  locked: false,
  roles: [],
  verificationModel: null,
  validating: false,
  usernameValidation: null,
  userNameValid: false,
  verifyType: null,
  routedFrom: null,
  userLookupValidation: null,
  signupModel: null,
  loginModel: null,
  samlQueryParams: null,
  externalSignin: null,
};

export function userAuthReducer(
  state = initialState,
  action: any
): UserAuthState {
  switch (action.type) {
    case UserAuthActionTypes.UpdateVerificationPartial:
      return {
        ...state,
        verificationModel: Object.assign(
          {},
          state.verificationModel,
          action.payload
        ),
      };

    case UserAuthActionTypes.VerifyGoogleSigninSuccess:
      return {
        ...state,
        signupModel: action.payload,
        externalSignin: true,
      };

    case UserAuthActionTypes.VerifyAppleSigninSuccess:
      return {
        ...state,
        signupModel: action.payload,
        externalSignin: true,
      };

    case UserAuthActionTypes.GetUser:
      return { ...state, loading: true, error: null };
    case UserAuthActionTypes.GetUserSuccess:
      return { ...state, loading: false, error: null, user: action.payload };
    case UserAuthActionTypes.GetUserFailure:
      return { ...state, loading: false, error: action.payload };

    case samlLogin.type:
      return {
        ...state,
        loading: true,
        error: null,
        loginModel: Object.assign({}, state.loginModel, action.payload.login),
        samlQueryParams: action.payload.params,
      };
    case samlLoginFailure.type:
      return { ...state, loading: false, error: action.payload };
    case UserAuthActionTypes.Login:
      return {
        ...state,
        loading: true,
        error: null,
        loginModel: Object.assign({}, state.loginModel, action.payload),
      };
    case UserAuthActionTypes.LoginSuccess:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          organisationId: action.payload.organisationId,
          organisationUserId: action.payload.organisationUserId,
        },
        loginModel: null,
        error: null,
      };
    case UserAuthActionTypes.LoginFailure:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loginModel: null,
      };
    case UserAuthActionTypes.AutoLogin:
      return { ...state, loading: true };

    case UserAuthActionTypes.AutoLoginFailure:
      return { ...state, loading: false };

    case UserAuthActionTypes.AutoLoginSuccess:
      return { ...state, loading: false };

    case UserAuthActionTypes.Lock:
      return { ...state, locked: true };
    case UserAuthActionTypes.UnLock:
      return { ...state, loading: true, error: null };
    case UserAuthActionTypes.UnLockSuccess:
      return { ...state, loading: false, error: null, locked: false };
    case UserAuthActionTypes.UnLockFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.Logout:
      return initialState;

    case UserAuthActionTypes.ForgotPassword:
      return {
        ...state,
        loading: true,
        error: null,
        routedFrom: 'forgot-password',
      };
    case UserAuthActionTypes.ForgotPasswordFailure:
      return { ...state, loading: false, error: action.payload };
    case UserAuthActionTypes.ForgotPasswordSuccess:
      return {
        ...state,
        loading: false,
        verificationModel: action.payload,
        verifyType: 'email',
      };

    case UserAuthActionTypes.ClearError:
      return { ...state, error: null, loginError: null, signUpError: null };

    case UserAuthActionTypes.SetRoles:
      return { ...state, roles: action.payload };

    case UserAuthActionTypes.Verify:
      return { ...state, loading: true, error: null };

    case UserAuthActionTypes.VerifySuccess:
      return {
        ...state,
        loading: false,
        verificationModel: action.payload.verification,
        routedFrom: action.payload.routedFrom,
      };

    case UserAuthActionTypes.VerifyFailure:
      return {
        ...state,
        loading: false,
        error: handleApiErrorSync(action.payload),
        verificationModel: action.payload.error.data,
      };

    case resetPassword:
      return { ...state, loading: true, error: null };

    case resetPasswordSuccess:
      return { ...state, loading: false };

    case resetPasswordFailure:
      return { ...state, loading: false, error: action.payload };

    case verifyCodeForEmail:
      return { ...state, loading: true, error: null };

    case verifyCodeForEmailSuccess:
      return {
        ...state,
        loading: false,
        verificationModel: { ...state.verificationModel, code: action.payload },
      };

    case verifyCodeForEmailFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.GetInvitation:
      return {
        ...state,
        loading: true,
        error: null,
        routedFrom: 'invitation',
      };
    case UserAuthActionTypes.GetInvitationSuccess:
      return { ...state, loading: false, verificationModel: action.payload };
    case UserAuthActionTypes.GetInvitationFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.VerifyPhoneNumber:
      return {
        ...state,
        loading: true,
        error: null,

        routedFrom: action.payload.routedFrom
          ? action.payload.routedFrom
          : state.routedFrom,
        verifyType: 'phoneNumber',
      };
    case UserAuthActionTypes.VerifyPhoneNumberSuccess:
      return { ...state, loading: false, verificationModel: action.payload };
    case UserAuthActionTypes.VerifyPhoneNumberFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.VerifyEmail:
      return {
        ...state,
        loading: true,
        error: null,

        verifyType: 'email',
      };
    case UserAuthActionTypes.VerifyEmailSuccess:
      return { ...state, loading: false, verificationModel: action.payload };
    case UserAuthActionTypes.VerifyEmailFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.SetVerificationToken:
      return {
        ...state,
        verificationModel: state.verificationModel
          ? { ...state.verificationModel, token: action.verificationToken }
          : <VerificationModel>{ token: action.verificationToken },
        signupModel: state.signupModel
          ? {
              ...state.signupModel,
              verificationToken: action.verificationToken,
            }
          : ({ verificationToken: action.verificationToken } as SignupModel),
      };

    case UserAuthActionTypes.UserLookup:
      return {
        ...state,
        loading: true,
        error: [],
        signupModel: state.signupModel
          ? {
              ...state.signupModel,
              licenceNumber: action.payload.licenceNumber,
              username: action.payload.emailAddress,
            }
          : ({
              licenceNumber: action.payload.licenceNumber,
              username: action.payload.emailAddress,
            } as SignupModel),
      };
    case UserAuthActionTypes.UserLookupSuccess:
      return { ...state, loading: false, userLookupValidation: action.payload };
    case UserAuthActionTypes.UserLookupFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.ValidateUsername:
      return {
        ...state,
        validating: true,
        error: null,
        usernameValidation: null,
      };
    case UserAuthActionTypes.ValidateUsernameSuccess:
      return {
        ...state,
        validating: false,
        usernameValidation: action.payload,
      };
    case UserAuthActionTypes.ValidateUsernameFailure:
      return { ...state, validating: false, error: action.payload };
    case UserAuthActionTypes.ResetValidateUsername:
      return { ...state, usernameValidation: null };

    case UserAuthActionTypes.Signup:
      return {
        ...state,
        loading: true,
        error: null,
        loginModel: action.payload,
      };
    case UserAuthActionTypes.SignupSuccess:
      return { ...state, loading: false };
    case UserAuthActionTypes.SignupFailure:
      return { ...state, loading: false, error: action.payload };

    case UserAuthActionTypes.ChangeUsernameFailure:
      return { ...state, error: action.payload };

    case UserAuthActionTypes.VerifyComplete:
      return {
        ...state,
        loginModel: {
          ...state.loginModel,
          verificationToken: action.payload.verificationToken,
        },
        verificationModel: {
          ...state.verificationModel,
          token: action.payload.verificationToken,
          maskedEmail: action.payload.maskedEmail,
          newUser: action.payload.newUser,
          firstName: action.payload.firstName,
          resetPassword: action.payload.resetPassword,
          username: action.payload.username,
        },
      };

    case sendMFACode.type: {
      return { ...state, loading: true, error: null };
    }
    case sendMFACodeSuccess.type: {
      return {
        ...state,
        loading: false,
        error: null,
        verificationModel: action.payload,
      };
    }
    case sendMFACodeFailure.type: {
      return { ...state, loading: false, error: action.payload };
    }

    case configureMFA.type: {
      return { ...state, loading: false };
    }

    case verifyMFA.type:
      return {
        ...state,
        loading: false,
        error: null,
        verificationModel: action.payload.verification,
        routedFrom: action.payload.routedFrom,
      };

    case verifyMFASuccess:
      return {
        ...state,
        loading: false,
        verificationModel: action.payload.verificationModel,
        routedFrom: 'mfa-login',
        verifyType: action.payload.verificationType,
      };

    case verifyMFAFailure:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
