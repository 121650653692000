import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { AuthenticationProviderModel, LoginModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  nonAuthorisedHttp: HttpClient;
  headers: HttpHeaders;
  host: string;
  samlHost: string;
  fleetProduct: string;
  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    @Inject('env') env: any
  ) {
    this.headers = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': env.fleetProduct,
    });
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.auth;
    this.samlHost = env.host + paths.samlAuth;
    this.fleetProduct = env.fleetProduct;
  }

  changePassword(payload: any) {
    return this.http
      .post(`${this.host}/changePassword`, payload, {
        observe: 'response',
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  forgotPassword(payload: any) {
    return this.nonAuthorisedHttp
      .post(`${this.host}/forgotPassword`, payload, {
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  resetPassword(payload: any) {
    return this.nonAuthorisedHttp
      .post(`${this.host}/resetPassword`, payload, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  changeUsername(payload: LoginModel) {
    return this.http
      .post(`${this.host}/changeUsername`, payload, {
        // headers: new HttpHeaders({
        //   Authorization: 'Bearer ' + jwt,
        //   'fleet-channel': 'WEB',
        //   'fleet-product': this.fleetProduct,
        // }),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  changeMfa(payload: any, login: LoginModel): Observable<any> {
    if (login) {
      //user is not fully logged in
      const authdata = btoa(login.username + ':' + login.password);
      const headers: HttpHeaders = new HttpHeaders({
        Authorization: 'Basic ' + authdata,
        'fleet-product': this.fleetProduct,
        'fleet-channel': 'WEB',
      });
      return this.nonAuthorisedHttp
        .post(`${this.host}/changeMFA`, payload, { headers: headers })
        .pipe(catchError(handleApiError));
    }
    return this.http
      .post(`${this.host}/changeMFA`, payload, {})
      .pipe(catchError(handleApiError));
  }

  changePhoneNumber(payload: LoginModel) {
    return this.http
      .post(`${this.host}/changePhoneNumber`, payload)
      .pipe(catchError(handleApiError));
  }

  getPermissions() {
    return this.http
      .get(`${this.host}/permissions`)
      .pipe(catchError(handleApiError));
  }

  samlLogin(
    login: LoginModel,
    urlSearchParams: URLSearchParams
  ): Observable<any> {
    let body = new HttpParams()
      .set('username', login.username)
      .set('password', login.password);
    if (login.verificationToken) {
      body = body.set('verificationToken', login.verificationToken);
    }

    const headers: HttpHeaders = new HttpHeaders({
      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,PATCH,DELETE',
    });
    return this.nonAuthorisedHttp
      .post(`${this.samlHost}?${urlSearchParams.toString()}`, body.toString(), {
        headers: headers,
        observe: 'response',
        withCredentials: true,
      })
      .pipe(catchError(handleApiError));
  }

  getRefreshToken(payload: any): Observable<any> {
    return this.http
      .post(`${this.host}/refreshToken`, payload, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  getProviderConfiguration(): Observable<AuthenticationProviderModel | any> {
    // const combinedHeaders = this.headers.set(
    //   'ngrok-skip-browser-warning',
    //   'true'
    // );

    return this.nonAuthorisedHttp
      .get(`${this.host}/provider/config`, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }
}
