import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validator,
  Validators,
} from '@angular/forms';
import { AccountModel, DriverModel, NetworkGroupModel } from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { SettingService } from '@fleet/setting';

@Component({
  selector: 'fleet-account-model-form',
  templateUrl: './account-model-form.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountModelFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountModelFormComponent),
      multi: true,
    },
  ],
})
export class AccountModelFormComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, Validator
{
  hideDisbursementFrequency = false;
  @Input() frequencyRequired = true;
  @Input() account: any;
  // @Input() provider: string;
  @Input() disbursementFrequencies: any[] = [];
  @Input() bankAccountTypes: any[] = [];

  @Input() padding = false;

  fleetProduct: string;

  _paymentType: string;
  @Input() set paymentType(value: string) {
    this._paymentType = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      if (value === 'DIRECT_DEBIT_BANK_ACCOUNT') {
        this.form.get('disbursementFrequency').clearValidators();
        this.form.get('disbursementFrequency').disable();

        this.form.get('accountType').clearValidators();
        this.form.get('accountType').disable();
      } else {
        this.form
          .get('disbursementFrequency')
          .setValidators([Validators.required]);
        this.form.get('disbursementFrequency').enable();

        if (value === 'STRIPE') {
          this.form.get('accountType').clearValidators();
          this.form.get('accountType').disable();
          this.form.get('accountType').updateValueAndValidity();
        } else if (value === 'EVEREE') {
          this.form.get('accountType').setValidators([Validators.required]);
          this.form.get('accountType').enable();
          this.form.get('accountType').updateValueAndValidity();

          this.settingService
            .getSettingDefinitionByPath$('network.disbursement')
            .subscribe({
              next: (evereeResponse) => {
                //we need to find out whether this driver can create a disbursement with frequencies or not.

                const evereeDisbursementMethod =
                  evereeResponse.setting.data.disbursementMethods.find(
                    (method: any) => method.paymentType === 'EVEREE'
                  );

                if (evereeDisbursementMethod) {
                  const frequencySelectionEnabled =
                    evereeDisbursementMethod.frequencySelectionEnabled;

                  if (this.fleetProduct === 'DRIVER') {
                    if (frequencySelectionEnabled) {
                      this.form
                        .get('disbursementFrequency')
                        .setValidators([Validators.required]);
                      this.hideDisbursementFrequency = false;
                    } else {
                      this.form.get('disbursementFrequency').clearValidators();
                      this.hideDisbursementFrequency = true;
                    }
                    this.form
                      .get('disbursementFrequency')
                      .updateValueAndValidity();
                  }
                }
                this.changeDetectorRef.markForCheck();
              },
              error: () => {},
            });
        }
      }

      this.form.get('disbursementFrequency').updateValueAndValidity();
    }
  }

  get paymentType() {
    return this._paymentType;
  }

  @ViewChild('bankCodeInput', { read: ElementRef }) bankCodeInput: ElementRef;
  @ViewChild('accountNameInput', { read: ElementRef })
  accountNameInput: ElementRef;
  bankCodeLength = '9';
  includeBankName = true;
  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private networkGroupService: NetworkGroupService,
    private settingService: SettingService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;

    this.networkGroupService.networkGroup$
      .pipe(takeUntilDestroyed())
      .subscribe((networkGroup: any) => {
        if (!this.form) {
          this.buildForm();
        }
        if (networkGroup) {
          if (networkGroup.locale === 'en-US') {
            this.form.get('bankCode').setValidators([
              Validators.required,
              Validators.pattern('^\\d{9}$'), // 9 digits for US routing number - but backend right now only takes 6
            ]);
            this.includeBankName = true;
            this.bankCodeLength = '9';
          } else {
            this.form
              .get('bankCode')
              .setValidators([
                Validators.required,
                Validators.pattern('^\\d{6}$'),
              ]);
            this.bankCodeLength = '6';

            this.form.get('accountType').clearValidators();
            this.form.get('accountType').updateValueAndValidity();
            this.form.get('bankName').clearValidators();
            this.form.get('bankName').updateValueAndValidity();
            this.includeBankName = false;
          }
          this.form.get('bankCode').updateValueAndValidity();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm();
    }
    this.form.valueChanges.subscribe((account: AccountModel | any) => {
      if (account && account.accountName) {
        account.accountName = account.accountName.toUpperCase();
      }

      this.onChange(account);
      this.onTouched();
    });
  }

  ngAfterViewInit(): void {
    if (this.paymentType && this.account) {
      if (this.paymentType === 'EVEREE') {
        this.form.enable();
      } else {
        this.form.disable();
      }
      this.form.updateValueAndValidity();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      bankCode: [null, [Validators.required]],
      bankName: [null, [Validators.required]],
      accountType: [null, Validators.required],
      accountNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(9),
          Validators.pattern('^[0-9\\s]+$'),
        ],
      ],
      accountName: [
        null,
        [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern('^[a-zA-Z0-9\\-\\s]+$'),
        ],
      ],
      disbursementFrequency: [
        null,
        this.frequencyRequired ? [Validators.required] : [],
      ],
    });
  }

  writeValue(value: AccountModel | any): void {
    //needs to always emit so we can trigger the search and display the bsb object
    if (!this.form) {
      this.buildForm();
    }

    if (value) {
      setTimeout(() => {
        this.form.patchValue(value);

        if (this.paymentType !== 'EVEREE') {
          this.form.get('bankCode').disable();
          this.form.get('accountNumber').disable();
        }

        if (
          this.paymentType !== 'BANK_ACCOUNT' &&
          this.paymentType !== 'EVEREE'
        ) {
          this.form.get('disbursementFrequency').disable();
          this.form.get('disbursementFrequency').clearValidators();
          this.form.get('disbursementFrequency').updateValueAndValidity();
        }
      }, 100);
      this.form.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
    } else {
      setTimeout(() => {
        //It is patched as null - reset form and enable
        this.form.reset();
        this.form.get('bankCode').enable();
        this.form.get('accountNumber').enable();
        if (this.paymentType === 'BANK_ACCOUNT') {
          //reset to daily - it is REQUIRED and is a radio button so should always be selected as one of the two options

          this.form.get('disbursementFrequency').enable();
          this.form.get('disbursementFrequency').updateValueAndValidity();
        }
      }, 100);
    }
    this.changeDetectorRef.markForCheck();
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  focusField(value: string) {
    if (value) {
      if (value === 'accountName') {
        setTimeout(() => this.accountNameInput.nativeElement.focus(), 0);
      }

      if (value === 'bankCode') {
        setTimeout(() => this.bankCodeInput.nativeElement.focus(), 0);
      }
    }
  }

  validate() {
    return this.form.valid
      ? null
      : { message: 'Account details are not valid' };
  }
}
