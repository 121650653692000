import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpBackend,
} from '@angular/common/http';

import { paths } from '@fleet/environment';
import {
  NoteModel,
  StateChangeModel,
  ApiResponse,
  PaymentMethodSearchResultModel,
  IssueModel,
  TravellerModel,
  ReferenceDataItemModel,
  PaymentMethodModel,
  TravellerProfileModel,
  DocumentModel,
  SignupModel,
} from '@fleet/model';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleApiError, encodeParams } from '@fleet/utilities';

@Injectable({ providedIn: 'root' })
export class TravellerApiService {
  host;
  fleetProduct: string;
  nonAuthorisedHttp: HttpClient;

  constructor(
    private http: HttpClient,
    @Inject('env') env: any,
    private backend: HttpBackend
  ) {
    this.host = env.host + paths.traveller;
    this.fleetProduct = env.fleetProduct;
    this.nonAuthorisedHttp = new HttpClient(backend);
  }

  searchTravellers(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/search`, {
        //note the /search
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getTraveller(travellerId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${travellerId}`, {})
      .pipe(catchError(handleApiError));
  }

  // getTravellerBookings(travellerId: string) {}

  getTravellerTransactions(params: any, travellerId: string) {
    return this.http
      .get(`${this.host}/${travellerId}/transaction`, {
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  getTravellerPaymentMethods(travellerId: string) {
    const params = {
      offset: '0',
      limit: '100',
    } as any;

    return this.http
      .get(`${this.host}/${travellerId}/paymentMethod`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  gettravellerIderrals(travellerId: string) {
    return this.http
      .get(`${this.host}/${travellerId}/referralCode`, {})
      .pipe(catchError(handleApiError));
  }

  getProfile(travellerId: string, travellerProfileId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${travellerId}/profile/${travellerProfileId}`, {})
      .pipe(catchError(handleApiError));
  }

  getTravellerPromotions(travellerId: string) {
    return this.http
      .get(`${this.host}/${travellerId}/promotion`, {})
      .pipe(catchError(handleApiError));
  }

  searchPaymentMethods(
    travellerId: string,
    params: any
  ):
    | Observable<HttpResponse<ApiResponse<PaymentMethodSearchResultModel[]>>>
    | Observable<any> {
    return this.http
      .get(`${this.host}/${travellerId}/paymentMethod`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchtravellerIderrals(travellerId: string) {
    return this.http
      .get(`${this.host}/${travellerId}/referral`)
      .pipe(catchError(handleApiError));
  }

  searchTransactions(travellerId: string, params: any) {
    return this.http
      .get(`${this.host}/${travellerId}/transaction`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchBookings(travellerId: string, params: any) {
    return this.http
      .get(`${this.host}/${travellerId}/booking`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchPromotions(travellerId: string) {
    return this.http
      .get(`${this.host}/${travellerId}/promotion`)
      .pipe(catchError(handleApiError));
  }

  activateTraveller(travellerId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${travellerId}/activate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  suspendTraveller(travellerId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${travellerId}/suspend`, stateChange)
      .pipe(catchError(handleApiError));
  }

  banTraveller(travellerId: string, stateChange: StateChangeModel) {
    return this.http
      .post(`${this.host}/${travellerId}/ban`, stateChange)
      .pipe(catchError(handleApiError));
  }

  disableTraveller(travellerId: string, stateChange: StateChangeModel) {
    //no disable?
    return this.http
      .post(`${this.host}/${travellerId}/disable`, stateChange)
      .pipe(catchError(handleApiError));
  }

  deleteTraveller(travellerId: string) {
    return this.http
      .delete(`${this.host}/${travellerId}`)
      .pipe(catchError(handleApiError));
  }

  resurrectTraveller(travellerId: string, stateChange: StateChangeModel) {
    //no resurrect?
    return this.http
      .post(`${this.host}/${travellerId}/activate`, stateChange)
      .pipe(catchError(handleApiError));
  }

  searchLifecycleTransitions(
    travellerId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/${travellerId}/lifecycleTransition`)
      .pipe(catchError(handleApiError));
  }

  signup(payload: SignupModel): Observable<any> {
    return this.http
      .post(`${this.host}/signup`, payload, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  signin(payload: any): Observable<any> {
    return this.http
      .post(`${this.host}/signin`, payload, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  signinJWT(jwt: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,
      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });

    return this.nonAuthorisedHttp
      .post(
        `${this.host}/signin`,
        {},
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(catchError(handleApiError));
  }

  // getTraveller(
  //   travellerId: string
  // ): Observable<ApiResponse<TravellerModel> | any> {
  //   return this.http
  //     .get(`${this.host}/${travellerId}`, {})
  //     .pipe(catchError(handleApiError));
  // }

  // banTraveller(travellerId: string, stateChange: StateChangeModel) {
  //   return this.http
  //     .post(`${this.host}/${travellerId}/ban`, stateChange)
  //     .pipe(catchError(handleApiError));
  // }

  // suspendTraveller(travellerId: string, stateChange: StateChangeModel) {
  //   return this.http
  //     .post(`${this.host}/${travellerId}/suspend`, stateChange)
  //     .pipe(catchError(handleApiError));
  // }

  // deleteTraveller(travellerId: string) {
  //   return this.http
  //     .delete(`${this.host}/${travellerId}`)
  //     .pipe(catchError(handleApiError));
  // }

  getPaymentMethod(
    travellerId: string,
    paymentMethodId: string
  ): Observable<PaymentMethodModel> | Observable<any> {
    return this.http
      .get(`${this.host}/${travellerId}/paymentMethod/${paymentMethodId}`)
      .pipe(catchError(handleApiError));
  }

  createPaymentMethod(travellerId: string, payment: PaymentMethodModel) {
    return this.http
      .post(`${this.host}/${travellerId}/paymentMethod`, payment)
      .pipe(catchError(handleApiError));
  }

  updatePaymentMethod(travellerId: string, payment: PaymentMethodModel) {
    return this.http
      .put(
        `${this.host}/${travellerId}/paymentMethod/${payment.paymentMethodId}`,
        payment
      )
      .pipe(catchError(handleApiError));
  }

  deletePaymentMethod(travellerId: any, paymentMethodId: any) {
    return this.http
      .delete(`${this.host}/${travellerId}/paymentMethod/${paymentMethodId}`)
      .pipe(catchError(handleApiError));
  }

  // activateTraveller(travellerId: string, stateChange: StateChangeModel) {
  //   return this.http
  //     .post(`${this.host}/${travellerId}/activate`, stateChange)
  //     .pipe(catchError(handleApiError));
  // }

  updateTraveller(
    traveller: any
  ): Observable<ApiResponse<TravellerModel> | any> {
    return this.http
      .put(`${this.host}/${traveller.travellerId}`, traveller)
      .pipe(catchError(handleApiError));
  }

  statuses(
    params: any
  ): Observable<ApiResponse<ReferenceDataItemModel[]> | IssueModel[] | any> {
    return this.http
      .get(`${this.host}/status`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  processDocument(travellerId: string, documentId: string) {
    return this.http
      .post(`${this.host}/${travellerId}/document/${documentId}`, {})
      .pipe(catchError(handleApiError));
  }

  updateProfile(
    travellerId: string,
    travellerProfileId: string,
    payload: any
  ): Observable<ApiResponse<TravellerProfileModel[]> | IssueModel[] | any> {
    return this.http
      .put(`${this.host}/${travellerId}/profile/${travellerProfileId}`, payload)
      .pipe(catchError(handleApiError));
  }

  searchDocuments(
    travellerId: string,
    params: any
  ): Observable<ApiResponse<DocumentModel[]> | any> {
    return this.http
      .get(`${this.host}/${travellerId}/document`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
