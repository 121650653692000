import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { IdentityModel, TravellerModel } from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppleApiService {
  host;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.signin;
  }

  verifyAuthorisationCode(payload: IdentityModel): Observable<any> {
    return this.http
      .post(`${this.host}/apple/verify`, payload, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }
}
