import { Injectable } from '@angular/core';
import { SettingsApiService } from '@fleet/api';
import {
  ApiResponse,
  IssueModel,
  SettingDefinitionModel,
  SettingModel,
  SettingSearchResultModel,
  SettingSharedDataModel,
} from '@fleet/model';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  settings: BehaviorSubject<any[]> = new BehaviorSubject([]);
  issues: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);
  constructor(private settingApiService: SettingsApiService) {}

  listDefinitions() {
    return this.settingApiService.listDefinitions().subscribe({
      next: (resp: ApiResponse<any[]>) => {
        this.settings.next(resp.data);
      },
      error: (issues: IssueModel[]) => {
        this.issues.next(issues);
      },
    });
  }

  get settings$() {
    return this.settings.asObservable();
  }

  get issues$() {
    return this.issues.asObservable();
  }

  settingByPath$(path: string) {
    return this.settings.pipe(
      map((settings) => settings.find((setting) => setting.path === path))
    );
  }

  getSettingDefinitionByPath$(path: string): Observable<any> {
    return this.settingByPath$(path).pipe(
      switchMap((setting: SettingSearchResultModel) => {
        if (setting && setting.settingDefinitionId) {
          return this.settingApiService.getSettingDefinition(
            setting.settingDefinitionId
          );
        } else {
          return of(null); // Return an observable of null to handle the error
        }
      }),
      map((resp: ApiResponse<SettingDefinitionModel>) => resp?.data || null) // Handle potential null response
    );
  }

  // upsertSetting(setting: SettingModel) {
  //   const currentSettings = this.settings.getValue();
  //   const settingIndex = currentSettings.findIndex(
  //     (currentSetting) => currentSetting.settingId === setting.settingId
  //   );

  //   if (settingIndex > -1) {
  //     // Update the existing setting
  //     currentSettings[settingIndex] = {
  //       ...currentSettings[settingIndex],
  //       settingId: setting.settingId,
  //     };
  //   } else {
  //     // Add the new setting
  //     // currentSettings.push(this.convertToSettingSearchResultModel(setting));
  //   }

  //   this.settings.next(currentSettings);
  // }

  convertToSettingSearchResultModel(
    setting: SettingModel
  ): SettingSearchResultModel {
    return {
      settingId: setting.settingId,
      settingDefinitionId: setting.settingDefinitionId,
      defaultSetting: setting.defaultSetting,
      path: setting.data.path,
      displayName: setting.data.displayName,
      description: setting.data.description,
      networkGroupId: setting?.data?.networkGroupId,
      networkId: setting?.data?.networkId,
    };
  }
}
