import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NetworkConditionsService } from '@fleet/network-shared';
import { ReferenceService } from '@fleet/reference';
import { SettingService } from '@fleet/setting';
@Injectable({
  providedIn: 'root',
})
export class InitialDataResolverTravellerService {
  constructor(
    private networkConditionService: NetworkConditionsService,
    private referenceService: ReferenceService,
    private settingService: SettingService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //this.networkConditionService.getAllNetworkTypes();
    this.referenceService.searchReferences('localities');
    this.settingService.listDefinitions();
  }
}
