import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  IssueModel,
  LoginModel,
  MultiFactorAuthenticationModel,
  VerificationModel,
} from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { catchError } from 'rxjs/operators';
import { AuthApiService } from '../auth/auth-api.service';
import { paths } from '@fleet/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerificationApiService {
  nonAuthorisedHttp: HttpClient;
  headers: HttpHeaders;
  host: string;
  fleetProduct: string;
  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    @Inject('env') env: any
  ) {
    this.headers = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': env.fleetProduct,
    });
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.verify;
    this.fleetProduct = env.fleetProduct;
  }

  getInvitation(activationKey: string) {
    return this.nonAuthorisedHttp
      .get(`${this.host}/invitation/${activationKey}`, {
        headers: this.headers,
      })
      .pipe(catchError(handleApiError));
  }

  sendEmailActivationCode(payload: any) {
    return this.nonAuthorisedHttp
      .post(`${this.host}/email`, payload, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }

  verifyEmail(
    verificationModel: VerificationModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    return this.nonAuthorisedHttp
      .post(`${this.host}/email`, verificationModel, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }

  verifyEmailWithJWT(
    verificationModel: VerificationModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    const jwt = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,
      'fleet-channel': 'WEB',
      'fleet-product': this.headers.get('fleet-product'),
    });
    return this.http
      .post(`${this.host}/email`, verificationModel, { headers: headers })
      .pipe(catchError(handleApiError));
  }

  verify(verificationModel: VerificationModel): Observable<any> {
    return this.nonAuthorisedHttp
      .post(`${this.host}`, verificationModel, { headers: this.headers })
      .pipe(catchError(handleApiError));
  }

  verifyFullResponse(verificationModel: VerificationModel): Observable<any> {
    return this.nonAuthorisedHttp.post(`${this.host}`, verificationModel, {
      headers: this.headers,
    });
  }

  verifyWithJWT(verificationModel: VerificationModel): Observable<any> {
    const jwt = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,
      'fleet-channel': 'WEB',
      'fleet-product': this.headers.get('fleet-product'),
    });
    return this.http.post(`${this.host}`, verificationModel, {
      headers: headers,
    });
  }

  verifyPhoneNumber(
    payload: any,
    includeJwt: boolean
  ): Observable<ApiResponse<VerificationModel> | any> {
    let headers = this.headers;
    if (includeJwt) {
      const jwt = localStorage.getItem('access_token');
      headers = new HttpHeaders({
        Authorization: 'Bearer ' + jwt,
        'fleet-channel': 'WEB',
        'fleet-product': this.headers.get('fleet-product'),
      });
    }
    return this.http
      .post(`${this.host}/phoneNumber`, payload, { headers: headers })
      .pipe(catchError(handleApiError));
  }

  verifyCreditCard(
    payload: any
  ): Observable<ApiResponse<VerificationModel> | any> {
    return this.http
      .post(`${this.host}/creditCard`, payload)
      .pipe(catchError(handleApiError));
  }

  verifyGoogleSignin(
    payload: VerificationModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    return this.http
      .post(`${this.host}/google/signin`, payload, {
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  verifyApplSignin(
    payload: VerificationModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    return this.http
      .post(`${this.host}/apple/signin`, payload)
      .pipe(catchError(handleApiError));
  }
  testEmdpoint() {
    return this.http
      .get(`http://localhost:3000/permissions`)
      .pipe(catchError(handleApiError));
  }

  verifyMfa(
    mfa: MultiFactorAuthenticationModel,
    login: LoginModel
  ): Observable<ApiResponse<VerificationModel> | any> {
    // const jwt = localStorage.getItem('access_token');
    // const headers = new HttpHeaders({
    //   Authorization: 'Bearer ' + jwt,
    //   'fleet-channel': 'WEB',
    //   'fleet-product': this.headers.get('fleet-product'),
    // });
    if (login) {
      //user is not fully logged in
      const authdata = btoa(login.username + ':' + login.password);
      const headers: HttpHeaders = new HttpHeaders({
        Authorization: 'Basic ' + authdata,
        'fleet-product': this.fleetProduct,
        'fleet-channel': 'WEB',
      });
      return this.nonAuthorisedHttp
        .post(`${this.host}/MFA`, mfa, { headers: headers })
        .pipe(catchError(handleApiError));
    }
    return this.http
      .post(`${this.host}/MFA`, mfa)
      .pipe(catchError(handleApiError));
  }

  sendMFACode(
    mfa: MultiFactorAuthenticationModel,
    login: LoginModel
  ): Observable<any> {
    return this.verifyMfa(mfa, login);
    // if (login) {
    //   const authdata = btoa(login.username + ':' + login.password);
    //   const headers: HttpHeaders = new HttpHeaders({
    //     Authorization: 'Basic ' + authdata,
    //     'fleet-product': this.fleetProduct,
    //     'fleet-channel': 'WEB',
    //   });
    //   return this.nonAuthorisedHttp
    //     .post(`${this.host}/sendMFACode`, mfa, { headers: headers })
    //     .pipe(catchError(handleApiError));
    // } else {
    //   return this.http
    //     .post(`${this.host}/sendMFACode`, mfa)
    //     .pipe(catchError(handleApiError));
    // }
  }
}
