import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  private host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.settings;
  }

  createSettingDefinition(settingDefinitionModel: any): Observable<any> {
    return this.http
      .post(`${this.host}/definition`, settingDefinitionModel)
      .pipe(catchError(handleApiError));
  }

  getSettingDefinition(settingDefinitionId: string): Observable<any> {
    return this.http
      .get(`${this.host}/definition/${settingDefinitionId}`)
      .pipe(catchError(handleApiError));
  }

  updateSettingDefinition(
    settingDefinitionId: string,
    settingDefinitionModel: any
  ): Observable<any> {
    return this.http
      .put(
        `${this.host}/definition/${settingDefinitionId}`,
        settingDefinitionModel
      )
      .pipe(catchError(handleApiError));
  }

  deleteSettingDefinition(settingDefinitionId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/definition/${settingDefinitionId}`)
      .pipe(catchError(handleApiError));
  }

  createSettingSection(settingSectionModel: any): Observable<any> {
    return this.http
      .post(`${this.host}/section`, settingSectionModel)
      .pipe(catchError(handleApiError));
  }

  createSetting(settingModel: any): Observable<any> {
    return this.http
      .post(`${this.host}`, settingModel)
      .pipe(catchError(handleApiError));
  }

  getSetting(settingId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${settingId}`)
      .pipe(catchError(handleApiError));
  }

  updateConfiguration(settingId: string, settingModel: any): Observable<any> {
    return this.http
      .put(`${this.host}/${settingId}`, settingModel)
      .pipe(catchError(handleApiError));
  }

  searchSettingDefinitions(searchParams: any): Observable<any> {
    return this.http
      .get(`${this.host}/definition`, { params: encodeParams(searchParams) })
      .pipe(catchError(handleApiError));
  }

  listDefinitions(): Observable<any> {
    return this.http
      .get(`${this.host}/definition`, {})
      .pipe(catchError(handleApiError));
  }
  getSettingDefinitionSharedData(settingDefinitionId: string): Observable<any> {
    return this.http
      .get(`${this.host}/definition/${settingDefinitionId}/sharedData`)
      .pipe(catchError(handleApiError));
  }
}
