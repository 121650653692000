export const networkMenuChildren = () => {
  return [
    {
      id: 'job',
      title: 'jobs',
      icon: 'place',
      link: '/job/search',
      type: 'collapsable',
      securityFunctions: ['JOB:read'],

      children: [
        {
          id: 'job.search',
          title: 'search',
          type: 'basic',
          icon: 'search',
          link: '/job/search',
          exactMatch: true,
          securityFunctions: ['JOB_SEARCH:read'],
        },
        {
          id: 'job.active',
          title: 'activeJobs',
          type: 'basic',
          icon: 'stream',
          link: '/job/active',
          securityFunctions: ['JOB_ACTIVITY:read'],
        },
        {
          id: 'job.new',
          title: 'newJob',
          type: 'basic',
          icon: 'add',
          link: '/job/new',
          securityFunctions: ['JOB_DETAIL:create'],
        },
        {
          id: 'job.edit',
          title: 'editJob',
          type: 'basic',
          hidden: () => hideItem(),
          link: '/job',
          securityFunctions: ['JOB_DETAIL:update'],
          classes: { wrapper: 'hidden' },
        },
      ],
    },
    {
      id: 'driver',
      title: 'drivers',
      icon: 'person',
      link: '/driver',
      type: 'collapsable',
      securityFunctions: ['DRIVER:read'],

      children: [
        {
          id: 'driver.search',
          title: 'search',
          type: 'basic',
          icon: 'search',
          link: '/driver/search',
          exactMatch: true,
          securityFunctions: ['DRIVER_SEARCH:read'],
        },
        {
          title: 'reviews',
          type: 'basic',
          icon: 'grading',
          link: '/driver/reviews',
          exactMatch: true,
          securityFunctions: ['DRIVER_REVIEW:read'],
        },
      ],
    },

    {
      id: 'operator',
      title: 'operators',
      icon: 'supervised_user_circle',
      link: '/operator/search',
      securityFunctions: ['OPERATOR:read'],

      type: 'collapsable',
      children: [
        {
          id: 'operator.search',
          title: 'search',
          link: '/operator/search',
          icon: 'search',
          type: 'basic',
          securityFunctions: ['OPERATOR_SEARCH:read'],
        },
        {
          id: 'operator.outstanding.invoice',
          title: 'outstandingInvoices',
          link: '/operator/outstanding-invoices',
          icon: 'request_quote',
          type: 'basic',
          securityFunctions: ['OPERATOR_OUTSTANDING_INVOICES:read'],
        },
      ],
    },
    {
      id: 'organisation',
      title: 'organisation',
      icon: 'business',
      link: '/organisation/search',
      securityFunctions: ['ORGANISATION:read'],
      type: 'collapsable',
      children: [
        {
          id: 'organisation.search',
          title: 'search',
          type: 'basic',
          icon: 'search',
          link: '/organisation/search',
          exactMatch: true,
          securityFunctions: ['ORGANISATION_SEARCH:read'],
        },
      ],
    },
    {
      id: 'traveller',
      title: 'travellers',
      icon: 'hail',
      link: '/traveller',
      type: 'collapsable',
      securityFunctions: ['TRAVELLER:read'],

      exactMatch: false,
      children: [
        {
          id: 'traveller.search',
          title: 'search',
          type: 'basic',
          icon: 'search',
          link: '/traveller/search',
          exactMatch: true,
          securityFunctions: ['TRAVELLER_SEARCH:read'],
        },
      ],
    },
    {
      id: 'vehicle',
      title: 'vehicles',
      icon: 'local_taxi',
      securityFunctions: ['VEHICLE:read'],

      link: '/vehicle',
      type: 'collapsable',
      exactMatch: false,
      children: [
        {
          id: 'vehicle.search',
          title: 'search',
          type: 'basic',
          icon: 'search',
          link: '/vehicle/search',
          exactMatch: true,
          securityFunctions: ['VEHICLE_SEARCH:read'],
        },
        {
          id: 'vehicle.fleet',
          title: 'activeVehicles',
          type: 'basic',
          icon: 'radar',
          link: '/vehicle/fleet',
          exactMatch: true,
          securityFunctions: ['VEHICLE_ACTIVE_SEARCH:read'],
        },
        {
          id: 'position',
          title: 'positionSearch',
          type: 'basic',
          icon: 'share_location',
          link: '/position',
          securityFunctions: ['POSITION_SEARCH:read'],
        },
      ],
    },
    {
      title: 'hardware',
      type: 'collapsable',
      icon: 'devices',
      id: 'hardware',
      link: '/hardware',
      securityFunctions: ['HARDWARE:read'],

      children: [
        {
          title: 'assets',
          type: 'basic',
          icon: 'heroicons_outline:device-mobile',
          link: '/hardware/asset',
          id: 'hardware.asset',
          securityFunctions: ['HARDWARE_ASSET_SEARCH:read'],
        },
        {
          title: 'orders',
          type: 'basic',
          icon: 'heroicons_outline:clipboard-list',
          link: '/hardware/order',
          id: 'hardware.order',
          securityFunctions: ['HARDWARE_ORDER_SEARCH:read'],
        },
        {
          title: 'shipments',
          type: 'basic',
          icon: 'heroicons_outline:truck',
          link: '/hardware/shipment',
          id: 'hardware.shipment',
          securityFunctions: ['HARDWARE_SHIPMENT_SEARCH:read'],
        },
        {
          title: 'returnAuthorisations',
          type: 'basic',
          icon: 'heroicons_outline:clipboard-check',
          link: '/hardware/return-authorisation',
          id: 'hardware.returns',
          securityFunctions: ['HARDWARE_RETURN_AUTHORISATION_SEARCH:read'],
        },
      ],
    },

    {
      id: 'finance',
      title: 'finance',
      icon: 'account_balance',

      type: 'collapsable',
      securityFunctions: ['FINANCE:read'],

      children: [
        {
          title: 'disbursements',
          type: 'collapsable',
          icon: 'heroicons_outline:currency-dollar',
          id: 'disbursement',
          securityFunctions: ['FINANCE_DISBURSEMENTS:read'],
          children: [
            {
              title: 'search',
              type: 'basic',
              icon: 'heroicons_outline:search',
              link: '/disbursement/search',
              id: 'disbursement.search',
              exactMatch: true,
              securityFunctions: ['FINANCE_DISBURSEMENT_SEARCH:read'],
            },
            {
              title: 'batches',
              type: 'basic',
              icon: 'heroicons_outline:clipboard-list',
              link: '/disbursement/batches',
              id: 'disbursement.batches',
              exactMatch: true,
              securityFunctions: ['FINANCE_DISBURSEMENT_BATCH:read'],
            },
          ],
        },

        {
          title: 'directDebits',
          type: 'collapsable',
          icon: 'heroicons_outline:cash',
          id: 'direct-debit',
          securityFunctions: ['FINANCE_DIRECT_DEBITS:read'],
          children: [
            {
              title: 'search',
              type: 'basic',
              icon: 'heroicons_outline:search',
              link: '/direct-debit/search',
              id: 'direct-debit.search',
              exactMatch: true,
              securityFunctions: ['FINANCE_DIRECT_DEBIT_SEARCH:read'],
            },
            {
              title: 'batches',
              type: 'basic',
              icon: 'heroicons_outline:clipboard-list',
              link: '/direct-debit/batches',
              id: 'direct-debit.batches',
              exactMatch: true,
              securityFunctions: ['FINANCE_DIRECT_DEBIT_BATCH:read'],
            },
          ],
        },
        {
          title: 'cabchargeImport',
          type: 'basic',
          icon: 'heroicons_outline:arrow-up-on-square-stack',
          link: 'cabcharge',
          id: 'import.cabcharge',
          securityFunctions: ['CABCHARGE_IMPORT_ADMIN:read'],
        },
      ],
    },

    {
      id: 'notifications',
      title: 'notifications',
      icon: 'notifications',
      link: '/notification/search',
      exactMatch: true,

      type: 'basic',
      securityFunctions: ['NOTIFICATIONS:read'],
    },
    {
      title: 'calls',
      type: 'basic',
      icon: 'heroicons_outline:phone',
      link: '/calls',

      securityFunctions: ['CALL:read'],
    },
    {
      id: 'tools',

      title: 'tools',
      type: 'collapsable',
      icon: 'hardware',
      link: '/geo-tools',
      fleetOnly: true,
      children: [
        // {
        //   title: 'Positions',
        //   type: 'basic',
        //   icon: 'pin_drop',
        //   link: '/geo-tools/position',
        // },
        {
          title: 'isochrones',
          type: 'basic',
          icon: 'radar',
          link: '/geo-tools/isochrone',
          fleetOnly: true,
        },
        // {
        //   title: 'Boundaries',
        //   type: 'basic',
        //   icon: 'map',
        //   link: '/geo-tools/boundary',
        // },
        {
          title: 'tolls',
          type: 'basic',
          icon: 'toll',
          link: '/geo-tools/toll',
          fleetOnly: true,
        },
        {
          title: 'polyline',
          type: 'basic',
          icon: 'bolt',
          link: '/geo-tools/polyline',
          fleetOnly: true,
        },
      ],
    },
    {
      title: 'settings',
      type: 'collapsable',
      icon: 'heroicons_outline:cog',
      id: 'group.settings',
      // link: '/settings',
      securityFunctions: ['NETWORK:read'],

      children: [
        {
          title: 'security',
          type: 'basic',
          icon: 'heroicons_outline:shield-check',
          link: '/settings/security',
          id: 'group.security',
          securityFunctions: ['NETWORK_SECURITY:read'],
        },
        {
          title: 'dispatch',
          type: 'basic',
          icon: 'heroicons_outline:clipboard-list',
          link: '/settings/area',
          id: 'group.dispatch',
          securityFunctions: ['NETWORK_DISPATCH:read'],
        },
        {
          title: 'management',
          type: 'basic',
          icon: 'heroicons_outline:clipboard',
          link: '/settings/management',
          id: 'group.management',
          securityFunctions: ['NETWORK_MANAGEMENT:read'],
        },
        {
          title: 'promotions',
          type: 'basic',
          icon: 'heroicons_outline:sparkles',
          link: '/settings/promotion',
          id: 'group.promotion',
          securityFunctions: ['PROMOTIONS:read'],
        },
        {
          title: 'integrations',
          type: 'basic',
          icon: 'hub',
          link: '/settings/integration',
          id: 'group.integration',
        },
        {
          title: 'brand',
          type: 'basic',
          icon: 'style',
          link: '/settings/brand',
          id: 'brand',
        },
        {
          title: 'configuration',
          type: 'basic',
          icon: 'settings',
          link: '/settings/configuration',
          id: 'configuration',
        },
      ],
    },

    {
      title: 'signOut',
      type: 'basic',
      icon: 'heroicons_outline:logout',
      link: '/auth/logout',
      id: 'signout',
    },
  ];
};

export const networkNavigation = () => [
  {
    type: 'group',
    children: networkMenuChildren(),
  },
];

export const networkNavigationNoGroups = () => [
  {
    type: 'group',
    children: networkMenuChildren().filter((s) => s.id !== 'network-group'),
  },
];

export const hideItem = () => {
  return true;
};

// import { FuseNavigationItem } from '@fleet/model';

// export const networkMenuChildren = [
//   {
//     id: 'job',
//     title: 'Jobs',
//     icon: 'place',
//     link: '/job/search',
//     type: 'collapsable',
//     securityFunctions: ['JOB:read'],

//     children: [
//       {
//         id: 'job.search',
//         title: 'Search',
//         type: 'basic',
//         icon: 'search',
//         link: '/job/search',
//         exactMatch: true,
//         securityFunctions: ['JOB_SEARCH:read'],
//       },
//       {
//         id: 'job.active',
//         title: 'Active Jobs',
//         type: 'basic',
//         icon: 'stream',
//         link: '/job/active',
//         securityFunctions: ['JOB_ACTIVITY:read'],
//       },
//       {
//         id: 'job.new',
//         title: 'New Job',
//         type: 'basic',
//         icon: 'add',
//         link: '/job/new',
//         securityFunctions: ['JOB_DETAIL:create'],
//       },
//       {
//         id: 'job.edit',
//         title: 'Edit Job',
//         type: 'basic',
//         hidden: () => hideItem(),
//         link: '/job',
//         securityFunctions: ['JOB_DETAIL:update'],
//         classes: { wrapper: 'hidden' },
//       },
//     ],
//   },
//   {
//     id: 'driver',
//     title: 'Drivers',
//     icon: 'person',
//     link: '/driver',
//     type: 'collapsable',
//     securityFunctions: ['DRIVER:read'],

//     children: [
//       {
//         id: 'driver.search',
//         title: 'Search',
//         type: 'basic',
//         icon: 'search',
//         link: '/driver/search',
//         exactMatch: true,
//         securityFunctions: ['DRIVER_SEARCH:read'],
//       },
//       {
//         title: 'Reviews',
//         type: 'basic',
//         icon: 'grading',
//         link: '/driver/reviews',
//         exactMatch: true,
//         securityFunctions: ['DRIVER_REVIEW:read'],
//       },
//     ],
//   },

//   {
//     id: 'operator',
//     title: 'Operators',
//     icon: 'supervised_user_circle',
//     link: '/operator/search',
//     securityFunctions: ['OPERATOR:read'],

//     type: 'collapsable',
//     children: [
//       {
//         id: 'operator.search',
//         title: 'Search',
//         link: '/operator/search',
//         icon: 'search',
//         type: 'basic',
//         securityFunctions: ['OPERATOR_SEARCH:read'],
//       },
//       {
//         id: 'operator.outstanding.invoice',
//         title: 'Outstanding Invoices',
//         link: '/operator/outstanding-invoices',
//         icon: 'request_quote',
//         type: 'basic',
//         securityFunctions: ['OPERATOR_OUTSTANDING_INVOICES:read'],
//       },
//     ],
//   },
//   {
//     id: 'organisation',
//     title: 'Organisations',
//     icon: 'business',
//     link: '/organisation/search',
//     securityFunctions: ['ORGANISATION:read'],
//     type: 'collapsable',
//     children: [
//       {
//         id: 'organisation.search',
//         title: 'Search',
//         type: 'basic',
//         icon: 'search',
//         link: '/organisation/search',
//         exactMatch: true,
//         securityFunctions: ['ORGANISATION_SEARCH:read'],
//       },
//     ],
//   },
//   {
//     id: 'traveller',
//     title: 'Travellers',
//     icon: 'hail',
//     link: '/traveller',
//     type: 'collapsable',
//     securityFunctions: ['TRAVELLER:read'],

//     exactMatch: false,
//     children: [
//       {
//         id: 'traveller.search',
//         title: 'Search',
//         type: 'basic',
//         icon: 'search',
//         link: '/traveller/search',
//         exactMatch: true,
//         securityFunctions: ['TRAVELLER_SEARCH:read'],
//       },
//     ],
//   },
//   {
//     id: 'vehicle',
//     title: 'Vehicles',
//     icon: 'local_taxi',
//     securityFunctions: ['VEHICLE:read'],

//     link: '/vehicle',
//     type: 'collapsable',
//     exactMatch: false,
//     children: [
//       {
//         id: 'vehicle.search',
//         title: 'Search',
//         type: 'basic',
//         icon: 'search',
//         link: '/vehicle/search',
//         exactMatch: true,
//         securityFunctions: ['VEHICLE_SEARCH:read'],
//       },
//       {
//         id: 'vehicle.fleet',
//         title: 'Active Vehicles',
//         type: 'basic',
//         icon: 'radar',
//         link: '/vehicle/fleet',
//         exactMatch: true,
//         securityFunctions: ['VEHICLE_ACTIVE_SEARCH:read'],
//       },
//       {
//         id: 'position',
//         title: 'Position Search',
//         type: 'basic',
//         icon: 'share_location',
//         link: '/position',
//         securityFunctions: ['POSITION_SEARCH:read'],
//       },
//     ],
//   },
//   {
//     title: 'Hardware',
//     type: 'collapsable',
//     icon: 'devices',
//     id: 'hardware',
//     link: '/hardware',
//     securityFunctions: ['HARDWARE:read'],

//     children: [
//       {
//         title: 'Assets',
//         type: 'basic',
//         icon: 'heroicons_outline:device-mobile',
//         link: '/hardware/asset',
//         id: 'hardware.asset',
//         securityFunctions: ['HARDWARE_ASSET_SEARCH:read'],
//       },
//       {
//         title: 'Orders',
//         type: 'basic',
//         icon: 'heroicons_outline:clipboard-list',
//         link: '/hardware/order',
//         id: 'hardware.order',
//         securityFunctions: ['HARDWARE_ORDER_SEARCH:read'],
//       },
//       {
//         title: 'Shipments',
//         type: 'basic',
//         icon: 'heroicons_outline:truck',
//         link: '/hardware/shipment',
//         id: 'hardware.shipment',
//         securityFunctions: ['HARDWARE_SHIPMENT_SEARCH:read'],
//       },
//       {
//         title: 'Return Authorisations',
//         type: 'basic',
//         icon: 'heroicons_outline:clipboard-check',
//         link: '/hardware/return-authorisation',
//         id: 'hardware.returns',
//         securityFunctions: ['HARDWARE_RETURN_AUTHORISATION_SEARCH:read'],
//       },
//     ],
//   },

//   {
//     id: 'finance',
//     title: 'Finance',
//     icon: 'account_balance',

//     type: 'collapsable',
//     securityFunctions: ['FINANCE:read'],

//     children: [
//       {
//         title: 'Disbursements',
//         type: 'collapsable',
//         icon: 'heroicons_outline:currency-dollar',
//         id: 'disbursement',
//         securityFunctions: ['FINANCE_SETTLEMENTS:read'],
//         children: [
//           {
//             title: 'Search',
//             type: 'basic',
//             icon: 'heroicons_outline:search',
//             link: '/disbursement/search',
//             id: 'disbursement.search',
//             exactMatch: true,
//             securityFunctions: ['FINANCE_DISBURSEMENT_SEARCH:read'],
//           },
//           {
//             title: 'Batches',
//             type: 'basic',
//             icon: 'heroicons_outline:clipboard-list',
//             link: '/disbursement/batches',
//             id: 'disbursement.batches',
//             exactMatch: true,
//             securityFunctions: ['FINANCE_DISBURSEMENT_BATCH:read'],
//           },
//         ],
//       },

//       {
//         title: 'Direct Debits',
//         type: 'collapsable',
//         icon: 'heroicons_outline:cash',
//         id: 'direct-debit',
//         securityFunctions: ['FINANCE_DIRECT_DEBITS:read'],
//         children: [
//           {
//             title: 'Search',
//             type: 'basic',
//             icon: 'heroicons_outline:search',
//             link: '/direct-debit/search',
//             id: 'direct-debit.search',
//             exactMatch: true,
//             securityFunctions: ['FINANCE_DIRECT_DEBIT_SEARCH:read'],
//           },
//           {
//             title: 'Batches',
//             type: 'basic',
//             icon: 'heroicons_outline:clipboard-list',
//             link: '/direct-debit/batches',
//             id: 'direct-debit.batches',
//             exactMatch: true,
//             securityFunctions: ['FINANCE_DIRECT_DEBIT_BATCH:read'],
//           },
//         ],
//       },
//       {
//         title: 'Cabcharge Import',
//         type: 'basic',
//         icon: 'heroicons_outline:arrow-up-on-square-stack',
//         link: 'cabcharge',
//         id: 'import.cabcharge',
//         securityFunctions: ['CABCHARGE_IMPORT_ADMIN:read'],
//       },
//     ],
//   },

//   {
//     id: 'notifications',
//     title: 'Notifications',
//     icon: 'notifications',
//     link: '/notification/search',
//     exactMatch: true,

//     type: 'basic',
//     securityFunctions: ['NOTIFICATIONS:read'],
//   },
//   {
//     title: 'Calls',
//     type: 'basic',
//     icon: 'heroicons_outline:phone',
//     link: '/calls',

//     securityFunctions: ['CALL:read'],
//   },
//   {
//     id: 'tools',

//     title: 'Tools',
//     type: 'collapsable',
//     icon: 'hardware',
//     link: '/geo-tools',
//     fleetOnly: true,
//     children: [
//       // {
//       //   title: 'Positions',
//       //   type: 'basic',
//       //   icon: 'pin_drop',
//       //   link: '/geo-tools/position',
//       // },
//       {
//         title: 'Isochrones',
//         type: 'basic',
//         icon: 'radar',
//         link: '/geo-tools/isochrone',
//         fleetOnly: true,
//       },
//       // {
//       //   title: 'Boundaries',
//       //   type: 'basic',
//       //   icon: 'map',
//       //   link: '/geo-tools/boundary',
//       // },
//       {
//         title: 'Tolls',
//         type: 'basic',
//         icon: 'toll',
//         link: '/geo-tools/toll',
//         fleetOnly: true,
//       },
//       {
//         title: 'Polyline',
//         type: 'basic',
//         icon: 'bolt',
//         link: '/geo-tools/polyline',
//         fleetOnly: true,
//       },
//     ],
//   },
//   {
//     title: 'Settings',
//     type: 'collapsable',
//     icon: 'heroicons_outline:cog',
//     id: 'group.settings',
//     // link: '/settings',
//     securityFunctions: ['NETWORK:read'],

//     children: [
//       {
//         title: 'Security',
//         type: 'basic',
//         icon: 'heroicons_outline:shield-check',
//         link: '/settings/security',
//         id: 'group.security',
//         securityFunctions: ['NETWORK_SECURITY:read'],
//       },
//       {
//         title: 'Dispatch',
//         type: 'basic',
//         icon: 'heroicons_outline:clipboard-list',
//         link: '/settings/area',
//         id: 'group.dispatch',
//         securityFunctions: ['NETWORK_DISPATCH:read'],
//       },
//       {
//         title: 'Management',
//         type: 'basic',
//         icon: 'heroicons_outline:clipboard',
//         link: '/settings/management',
//         id: 'group.management',
//         securityFunctions: ['NETWORK_MANAGEMENT:read'],
//       },
//       {
//         title: 'Promotions',
//         type: 'basic',
//         icon: 'heroicons_outline:sparkles',
//         link: '/settings/promotion',
//         id: 'group.promotion',
//         securityFunctions: ['PROMOTIONS:read'],
//       },
//       {
//         title: 'Brand',
//         type: 'basic',
//         icon: 'style',
//         link: '/settings/brand',
//         id: 'brand',
//       },
//     ],
//   },

//   {
//     title: 'Sign Out',
//     type: 'basic',
//     icon: 'heroicons_outline:logout',
//     link: '/auth/logout',
//     id: 'signout',
//   },
// ];

// export const networkNavigation = [
//   {
//     type: 'group',
//     children: networkMenuChildren,
//   },
// ];

// export const networkNavigationNoGroups = [
//   {
//     type: 'group',
//     children: networkMenuChildren.filter((s) => s.id !== 'network-group'),
//   },
// ];

// export const hideItem = () => {
//   return true;
// };

// import { TranslocoService } from '@jsverse/transloco';
// export const networkMenuChildren = (translocoService: TranslocoService) => {
//   return [
//     {
//       id: 'job',
//       title: translocoService.translate('jobs'),
//       icon: 'place',
//       link: '/job/search',
//       type: 'collapsable',
//       securityFunctions: ['JOB:read'],
//       children: [
//         {
//           id: 'job.search',
//           title: translocoService.translate('search'),
//           type: 'basic',
//           icon: 'search',
//           link: '/job/search',
//           exactMatch: true,
//           securityFunctions: ['JOB_SEARCH:read'],
//         },
//         {
//           id: 'job.active',
//           title: translocoService.translate('activeJobs'),
//           type: 'basic',
//           icon: 'stream',
//           link: '/job/active',
//           securityFunctions: ['JOB_ACTIVITY:read'],
//         },
//         {
//           id: 'job.new',
//           title: translocoService.translate('newJob'),
//           type: 'basic',
//           icon: 'add',
//           link: '/job/new',
//           securityFunctions: ['JOB_DETAIL:create'],
//         },
//         {
//           id: 'job.edit',
//           title: translocoService.translate('editJob'),
//           type: 'basic',
//           hidden: () => hideItem(),
//           link: '/job',
//           securityFunctions: ['JOB_DETAIL:update'],
//           classes: { wrapper: 'hidden' },
//         },
//       ],
//     },
//     {
//       id: 'driver',
//       title: translocoService.translate('drivers'),
//       icon: 'person',
//       link: '/driver',
//       type: 'collapsable',
//       securityFunctions: ['DRIVER:read'],
//       children: [
//         {
//           id: 'driver.search',
//           title: translocoService.translate('search'),
//           type: 'basic',
//           icon: 'search',
//           link: '/driver/search',
//           exactMatch: true,
//           securityFunctions: ['DRIVER_SEARCH:read'],
//         },
//         {
//           title: translocoService.translate('reviews'),
//           type: 'basic',
//           icon: 'grading',
//           link: '/driver/reviews',
//           exactMatch: true,
//           securityFunctions: ['DRIVER_REVIEW:read'],
//         },
//       ],
//     },
//     {
//       id: 'operator',
//       title: translocoService.translate('operators'),
//       icon: 'supervised_user_circle',
//       link: '/operator/search',
//       securityFunctions: ['OPERATOR:read'],
//       type: 'collapsable',
//       children: [
//         {
//           id: 'operator.search',
//           title: translocoService.translate('search'),
//           link: '/operator/search',
//           icon: 'search',
//           type: 'basic',
//           securityFunctions: ['OPERATOR_SEARCH:read'],
//         },
//         {
//           id: 'operator.outstanding.invoice',
//           title: translocoService.translate('outstandingInvoices'),
//           link: '/operator/outstanding-invoices',
//           icon: 'request_quote',
//           type: 'basic',
//           securityFunctions: ['OPERATOR_OUTSTANDING_INVOICES:read'],
//         },
//       ],
//     },
//     {
//       id: 'organisation',
//       title: translocoService.translate('organisation'),
//       icon: 'business',
//       link: '/organisation/search',
//       securityFunctions: ['ORGANISATION:read'],
//       type: 'collapsable',
//       children: [
//         {
//           id: 'organisation.search',
//           title: translocoService.translate('search'),
//           type: 'basic',
//           icon: 'search',
//           link: '/organisation/search',
//           exactMatch: true,
//           securityFunctions: ['ORGANISATION_SEARCH:read'],
//         },
//       ],
//     },
//     {
//       id: 'traveller',
//       title: translocoService.translate('travellers'),
//       icon: 'hail',
//       link: '/traveller',
//       type: 'collapsable',
//       securityFunctions: ['TRAVELLER:read'],
//       exactMatch: false,
//       children: [
//         {
//           id: 'traveller.search',
//           title: translocoService.translate('search'),
//           type: 'basic',
//           icon: 'search',
//           link: '/traveller/search',
//           exactMatch: true,
//           securityFunctions: ['TRAVELLER_SEARCH:read'],
//         },
//       ],
//     },
//     {
//       id: 'vehicle',
//       title: translocoService.translate('vehicles'),
//       icon: 'local_taxi',
//       securityFunctions: ['VEHICLE:read'],
//       link: '/vehicle',
//       type: 'collapsable',
//       exactMatch: false,
//       children: [
//         {
//           id: 'vehicle.search',
//           title: translocoService.translate('search'),
//           type: 'basic',
//           icon: 'search',
//           link: '/vehicle/search',
//           exactMatch: true,
//           securityFunctions: ['VEHICLE_SEARCH:read'],
//         },
//         {
//           id: 'vehicle.fleet',
//           title: translocoService.translate('activeVehicles'),
//           type: 'basic',
//           icon: 'radar',
//           link: '/vehicle/fleet',
//           exactMatch: true,
//           securityFunctions: ['VEHICLE_ACTIVE_SEARCH:read'],
//         },
//         {
//           id: 'position',
//           title: translocoService.translate('positionSearch'),
//           type: 'basic',
//           icon: 'share_location',
//           link: '/position',
//           securityFunctions: ['POSITION_SEARCH:read'],
//         },
//       ],
//     },
//     {
//       title: translocoService.translate('hardware'),
//       type: 'collapsable',
//       icon: 'devices',
//       id: 'hardware',
//       link: '/hardware',
//       securityFunctions: ['HARDWARE:read'],
//       children: [
//         {
//           title: translocoService.translate('assets'),
//           type: 'basic',
//           icon: 'heroicons_outline:device-mobile',
//           link: '/hardware/asset',
//           id: 'hardware.asset',
//           securityFunctions: ['HARDWARE_ASSET_SEARCH:read'],
//         },
//         {
//           title: translocoService.translate('orders'),
//           type: 'basic',
//           icon: 'heroicons_outline:clipboard-list',
//           link: '/hardware/order',
//           id: 'hardware.order',
//           securityFunctions: ['HARDWARE_ORDER_SEARCH:read'],
//         },
//         {
//           title: translocoService.translate('shipments'),
//           type: 'basic',
//           icon: 'heroicons_outline:truck',
//           link: '/hardware/shipment',
//           id: 'hardware.shipment',
//           securityFunctions: ['HARDWARE_SHIPMENT_SEARCH:read'],
//         },
//         {
//           title: translocoService.translate('returnAuthorisations'),
//           type: 'basic',
//           icon: 'heroicons_outline:clipboard-check',
//           link: '/hardware/return-authorisation',
//           id: 'hardware.returns',
//           securityFunctions: ['HARDWARE_RETURN_AUTHORISATION_SEARCH:read'],
//         },
//       ],
//     },
//     {
//       id: 'finance',
//       title: translocoService.translate('finance'),
//       icon: 'account_balance',
//       type: 'collapsable',
//       securityFunctions: ['FINANCE:read'],
//       children: [
//         {
//           title: translocoService.translate('disbursements'),
//           type: 'collapsable',
//           icon: 'heroicons_outline:currency-dollar',
//           id: 'disbursement',
//           securityFunctions: ['FINANCE_SETTLEMENTS:read'],
//           children: [
//             {
//               title: translocoService.translate('search'),
//               type: 'basic',
//               icon: 'heroicons_outline:search',
//               link: '/disbursement/search',
//               id: 'disbursement.search',
//               exactMatch: true,
//               securityFunctions: ['FINANCE_DISBURSEMENT_SEARCH:read'],
//             },
//             {
//               title: translocoService.translate('batches'),
//               type: 'basic',
//               icon: 'heroicons_outline:clipboard-list',
//               link: '/disbursement/batches',
//               id: 'disbursement.batches',
//               exactMatch: true,
//               securityFunctions: ['FINANCE_DISBURSEMENT_BATCH:read'],
//             },
//           ],
//         },
//         {
//           title: translocoService.translate('directDebits'),
//           type: 'collapsable',
//           icon: 'heroicons_outline:cash',
//           id: 'direct-debit',
//           securityFunctions: ['FINANCE_DIRECT_DEBITS:read'],
//           children: [
//             {
//               title: translocoService.translate('search'),
//               type: 'basic',
//               icon: 'heroicons_outline:search',
//               link: '/direct-debit/search',
//               id: 'direct-debit.search',
//               exactMatch: true,
//               securityFunctions: ['FINANCE_DIRECT_DEBIT_SEARCH:read'],
//             },
//             {
//               title: translocoService.translate('batches'),
//               type: 'basic',
//               icon: 'heroicons_outline:clipboard-list',
//               link: '/direct-debit/batches',
//               id: 'direct-debit.batches',
//               exactMatch: true,
//               securityFunctions: ['FINANCE_DIRECT_DEBIT_BATCH:read'],
//             },
//           ],
//         },
//         {
//           title: translocoService.translate('cabchargeImport'),
//           type: 'basic',
//           icon: 'heroicons_outline:arrow-up-on-square-stack',
//           link: 'cabcharge',
//           id: 'import.cabcharge',
//           securityFunctions: ['CABCHARGE_IMPORT_ADMIN:read'],
//         },
//       ],
//     },
//     {
//       id: 'notifications',
//       title: translocoService.translate('notifications'),
//       icon: 'notifications',
//       link: '/notification/search',
//       exactMatch: true,
//       type: 'basic',
//       securityFunctions: ['NOTIFICATIONS:read'],
//     },
//     {
//       title: translocoService.translate('calls'),
//       type: 'basic',
//       icon: 'heroicons_outline:phone',
//       link: '/calls',
//       securityFunctions: ['CALL:read'],
//     },
//     {
//       id: 'tools',
//       title: translocoService.translate('tools'),
//       type: 'collapsable',
//       icon: 'hardware',
//       link: '/geo-tools',
//       fleetOnly: true,
//       children: [
//         // {
//         //   title: 'Positions',
//         //   type: 'basic',
//         //   icon: 'pin_drop',
//         //   link: '/geo-tools/position',
//         // },
//         {
//           title: translocoService.translate('isochrones'),
//           type: 'basic',
//           icon: 'radar',
//           link: '/geo-tools/isochrone',
//           fleetOnly: true,
//         },
//         // {
//         //   title: 'Boundaries',
//         //   type: 'basic',
//         //   icon: 'map',
//         //   link: '/geo-tools/boundary',
//         // },
//         {
//           title: translocoService.translate('tolls'),
//           type: 'basic',
//           icon: 'toll',
//           link: '/geo-tools/toll',
//           fleetOnly: true,
//         },
//         {
//           title: translocoService.translate('polyline'),
//           type: 'basic',
//           icon: 'bolt',
//           link: '/geo-tools/polyline',
//           fleetOnly: true,
//         },
//       ],
//     },
//     {
//       title: translocoService.translate('settings'),
//       type: 'collapsable',
//       icon: 'heroicons_outline:cog',
//       id: 'group.settings',
//       // link: '/settings',
//       securityFunctions: ['NETWORK:read'],
//       children: [
//         {
//           title: translocoService.translate('security'),
//           type: 'basic',
//           icon: 'heroicons_outline:shield-check',
//           link: '/settings/security',
//           id: 'group.security',
//           securityFunctions: ['NETWORK_SECURITY:read'],
//         },
//         {
//           title: translocoService.translate('dispatch'),
//           type: 'basic',
//           icon: 'heroicons_outline:clipboard-list',
//           link: '/settings/area',
//           id: 'group.dispatch',
//           securityFunctions: ['NETWORK_DISPATCH:read'],
//         },
//         {
//           title: translocoService.translate('management'),
//           type: 'basic',
//           icon: 'heroicons_outline:clipboard',
//           link: '/settings/management',
//           id: 'group.management',
//           securityFunctions: ['NETWORK_MANAGEMENT:read'],
//         },
//         {
//           title: translocoService.translate('promotions'),
//           type: 'basic',
//           icon: 'heroicons_outline:sparkles',
//           link: '/settings/promotion',
//           id: 'group.promotion',
//           securityFunctions: ['PROMOTIONS:read'],
//         },

//         {
//           title: translocoService.translate('integrations'),
//           type: 'basic',
//           icon: 'hub',
//           link: '/settings/integration',
//           id: 'group.integration',
//         },
//         {
//           title: translocoService.translate('brand'),
//           type: 'basic',
//           icon: 'style',
//           link: '/settings/brand',
//           id: 'brand',
//         },
//       ],
//     },
//     {
//       title: translocoService.translate('signOut'),
//       type: 'basic',
//       icon: 'heroicons_outline:logout',
//       link: '/auth/logout',
//       id: 'signout',
//     },
//   ];
// };
// export const networkNavigation = (translocoService: TranslocoService) => [
//   {
//     type: 'group',
//     children: networkMenuChildren(translocoService),
//   },
// ];
// export const networkNavigationNoGroups = (
//   translocoService: TranslocoService
// ) => [
//   {
//     type: 'group',
//     children: networkMenuChildren(translocoService).filter(
//       (s) => s.id !== 'network-group'
//     ),
//   },
// ];
// export const hideItem = () => {
//   return true;
// };
